
import { defineComponent, onMounted, ref } from "vue";
import BaseService from "@/services/admin/BaseService";
import Disciplina from "@/interfaces/Disciplina";
import { useRoute } from "vue-router";
import Aula from "@/interfaces/Aula";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import DuvidaForm from "@/components/aluno/duvidas/DuvidaForm.vue";
import store from "@/store";

export default defineComponent({
  components: { DuvidaForm },
  props: { aulaRouterId: { required: false } },
  setup(props) {
    const $q = useQuasar();
    const route = useRoute();
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const loading = ref<boolean>(false);
    const disciplina = ref<Disciplina>();
    const aulaSelecionada = ref<Aula>();
    const modalDuvidaForm = ref();

    const find = (): void => {
      loading.value = true;
      BaseService.list(`/aluno/ver-aula/${route.params.id.toString()}`)
        .then((res) => {
          disciplina.value = res.data;
          vistaPorUltimo();
          if (props.aulaRouterId != "") {
            disciplina.value?.assuntos.forEach((assunto) => {
              assunto.aulas.forEach((aula) => {
                if (aula.id == props.aulaRouterId) {
                  alterarAula(aula);
                }
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const alterarAula = (aula: Aula) => {
      aulaSelecionada.value = aula;
    };

    const vistaPorUltimo = () => {
      let aulas: Aula[] = [];
      disciplina.value?.assuntos.forEach((assunto) => {
        assunto.aulas.forEach((aula) => {
          if (!aula.aula_assistida) {
            aulas.push(aula);
          }
        });
      });

      if (aulas.length > 0) {
        alterarAula(aulas[0]);
      }
    };

    const marcarAula = () => {
      if (aulaSelecionada.value) {
        BaseService.list(`/aluno/marcar-aula/${aulaSelecionada.value.id}`)
          .then(() => {
            atualizarAulaMarcada();
            showNotify({
              type: "positive",
              message: aulaSelecionada.value?.aula_assistida
                ? "Aula marcada como assistida"
                : "Aula marcada como não assistida",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const atualizarAulaMarcada = (): void => {
      if (aulaSelecionada.value) {
        aulaSelecionada.value.aula_assistida =
          !aulaSelecionada.value.aula_assistida;
      }
    };

    const criarDuvida = (): void => {
      modalDuvidaForm.value.open(aulaSelecionada.value);
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      find();
    });

    return {
      url,
      loading,
      disciplina,
      alterarAula,
      aulaSelecionada,
      atualizarAulaMarcada,
      marcarAula,
      criarDuvida,
      modalDuvidaForm,
      find,
      vistaPorUltimo,
      store,
    };
  },
});
